<template>
  <b-card>
    <!-- form -->
    <b-form ref="form" @submit.prevent="submitPassword">
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon icon="LockIcon" size="18" />
            <h4 class="mb-0 ml-75">Dados Segurança</h4>
          </div>
        </b-col>
        <!-- old password -->
        <b-col md="6">
          <b-form-group label="Senha Antiga" label-for="account-old-password">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="dataPassword.currentPassword"
                name="old-password"
                :type="passwordFieldTypeOld"
                placeholder="Senha Antiga"
                required />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <validation-observer ref="infoPass" tag="form">
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group label="Senha" label-for="senha">
              <validation-provider
                #default="{ errors }"
                name="senha"
                vid="senha"
                rules="required">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="password"
                    v-model="dataPassword.newPassword"
                    :type="passwordFieldTypeNew"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Senha Nova" />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->
          <b-col md="6">
            <b-form-group label="Confirmar senha" label-for="c-senha">
              <validation-provider
                #default="{ errors }"
                name="Confirmar senha"
                rules="required|confirmed:senha">
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="c-senha"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeNew"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Confirmar nova senha" />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <b-col cols="12">
            <hr class="my-2">
          </b-col>

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="validationFormInfo">
              Salvar Mudanças
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      dataPassword: {
        currentPassword: '',
        newPassword: '',
      },
      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  methods: {
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoPass.validate().then(success => {
          if (success) {
            this.submitPassword()
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    async submitPassword() {
      try {
        const response = await this.$http.post(
          '/api/services/app/Profile/ChangePassword',
          this.dataPassword,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )
        if (response) {
          this.msgSuccess()
        }
      } catch (error) {
        this.msgError()
      }
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados registrados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao gravar dados',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style scoped>
.is-invalid {
  border-color: #d8d6de !important;
}
</style>
