<template>
  <b-card>
    <b-form @submit.prevent="alterData">
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <feather-icon icon="InfoIcon" size="18" />
            <h4 class="mb-0 ml-75">Dados Notificações</h4>
          </div>
        </b-col>

        <b-col cols="12" class="mb-2">
          <b-form-checkbox
            id="accountSwitch1"
            v-model="notificationData.getNotification"
            :checked="renot"
            name="getNotification"
            switch
            inline>
            <span>Receber Novidades de Investimentos da Plataforma Divvy</span>
          </b-form-checkbox>
        </b-col>
        <b-col cols="12" class="mb-2">
          <b-form-checkbox
            id="accountSwitch2"
            v-model="notificationData.receiveNotificationsInvestiments"
            :checked="reinfo"
            name="receiveNotificationsInvestiments"
            switch
            inline>
            <span>Receber Informações dos Meus Investimentos</span>
          </b-form-checkbox>
        </b-col>

        <b-col cols="12">
          <hr class="my-2">
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mt-2 mr-1">
            Salvar Mudanças
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BRow,
  BCol,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      dataUser: {
        id: localStorage.getItem('id'),
        name: '',
      },
      renot: false,
      reinfo: false,
      notificationData: {
        getNotification: false,
        receiveNotificationsInvestiments: false,
        userId: localStorage.getItem('id'),
        id: null,
      },
    }
  },

  async created() {
    const responseUser = await this.$http.get(
      `/api/services/app/UserConfigurations/GetUserConfigurationForUserId?UserId=${this.dataUser.id}`,
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )

    this.notificationData.id = responseUser.data.result.userConfiguration.id
    this.notificationData.getNotification = responseUser.data.result.userConfiguration.getNotification
    this.notificationData.receiveNotificationsInvestiments = responseUser.data.result.userConfiguration.receiveNotificationsInvestiments
  },
  methods: {
    async alterData() {
      try {
        const responseNotification = await this.$http.post(
          '/api/services/app/UserConfigurations/CreateOrEdit',
          this.notificationData,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )

        if (responseNotification) {
          this.msgSuccess()
        }
      } catch (error) {
        this.msgError()
      }
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados registrados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao gravar dados',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
