<template>
  <b-card>
    <b-form @submit.prevent="alterData">
      <validation-observer ref="validateForm">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-2">
              <feather-icon icon="CoffeeIcon" size="18" />
              <h4 class="mb-0 ml-75">Dados Profissionais</h4>
            </div>
          </b-col>

          <b-col md="4">
            <b-form-group label-for="account-empresa" label="Empresa">
              <validation-provider #default="{ errors }" name="Empresa" rules="required">
                <b-form-input
                    id="account-empresa"
                    v-model="professionalData.company"
                    placeholder="Empresa" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Profissão" label-for="account-prof">
              <validation-provider #default="{ errors }" name="Profissão" rules="required">
              <b-form-input
                id="account-prof"
                v-model="professionalData.profession"
                placeholder="Profissão" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label-for="account-cargo" label="Cargo">
              <validation-provider #default="{ errors }" name="Cargo" rules="required">
              <b-form-input
                id="account-cargo"
                v-model="professionalData.office"
                placeholder="Cargo" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <hr class="my-2">
          </b-col>

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="mt-1 mr-1">
              Salvar Mudanças
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dataUser: {
        id: localStorage.getItem('id'),
        name: '',
      },
      professionalData: {
        company: null,
        profession: null,
        office: null,
        id: null,
      },
    }
  },

  async mounted() {
    const responseUser = await this.$http.get(
      `/api/services/app/UserProfessionalDatas/GetUserProfessionalDataByUser?userId=${this.dataUser.id}`,
      {
        headers: {
          accept: 'application/json',
          'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
        },
      },
    )
    this.professionalData.profession = responseUser.data.result.userProfessionalData.profession
    this.professionalData.company = responseUser.data.result.userProfessionalData.company
    this.professionalData.office = responseUser.data.result.userProfessionalData.office
    this.professionalData.id = responseUser.data.result.userProfessionalData.id
  },
  methods: {
    async alterData() {      
      const valid = (await this.$refs.validateForm.validate()) 
      if(valid === false) {
        return
      }
      try {
        const dataProfessional = {
          company: this.professionalData.company,
          profession: this.professionalData.profession,
          office: this.professionalData.office,
          userId: this.dataUser.id,
          id: this.professionalData.id,
        }
        const response = await this.$http.post(
          '/api/services/app/UserProfessionalDatas/CreateOrEdit',
          dataProfessional,
          {
            headers: {
              accept: 'application/json',
              'X-XSRF-TOKEN': localStorage.getItem('encryptedAccessToken'),
            },
          },
        )
        if (response) {
          this.msgSuccess()
        }
      } catch (error) {
        this.msgError()
      }
    },

    // success
    msgSuccess() {
      this.$swal({
        icon: 'success',
        title: 'Dados registrados com sucesso',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    // error
    msgError() {
      this.$swal({
        icon: 'error',
        title: 'Erro ao gravar dados',
        showConfirmButton: false,
        timer: 2600,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
